import moment from 'moment';
import RestClientObj from 'services/restClient';
import React from 'react';
import { Tag, message } from 'antd'
import XLSX from 'xlsx'

class UtilsObj {

    static dateFormat1 = 'YYYY/MM/DD';
    static dateFormat2 = 'DD [de] MMM [de] YYYY';
    static dateFormat3 = 'DD/MMM/YYYY';
    static dateFormat4 = 'YYYY-MM-DD';
    static dateFormat5 = 'ddd, DD [de] MMMM';
    static dateFormat6 = 'ddd, DD/MMM';
    static dateFormat7 = 'MMM D, YYYY HH:mm'
    static dateFormat8 = 'DD [de] MMM [de] YYYY HH:mm';
    static dateFormat9 = 'MMM D, YYYY'
    static dateFormat10 = 'DD [-] MMM [-] YYYY';
    static dateFormat11 = 'DD[-]MMM HH:mm'
    static dateFormat12 = 'DD-MM-YYYY'


    static monthFormat1 = 'YYYY-MM';
    static monthFormat2 = 'MMM [de] YYYY';
    static monthFormat3 = 'MMM/YYYY';

    static hourFormat1 = "HH:mm";
    static hourFormat2 = "hh:mm a";

    static timeFormat1 = "YYYY-MM-DD HH:mm";
    static timeFormat2 = "ddd, DD/MMM, hh:mm a";
    static timeFormat3 = "DD-MM-YYYY HH:mm"

    static icons_pending_monitoreo = {
        'PENDIENTE': 'warning',
        'NO_PENDIENTE': 'check'
    }

    static MAX_DIAS_ESTADO_CREDENCIALES = 30

    static ESTADO_CREDENCIALES_OK = {
        icon: 'check-circle',
        color: '#52c41a',
        state: 'OK'
    }

    static ESTADO_CREDENCIALES_NO_REGISTRADO = {
        icon: 'question-circle',
        color: '#d7cbcb',
        state: 'PENDING'
    }

    static ESTADO_CREDENCIALES_BANNED = {
        icon: 'stop',
        color: '#eb2f96',
        state: 'BANNED'
    }

    static ESTADO_CREDENCIALES_POR_CADUCAR = {
        icon: 'warning',
        color: '#FF8800',
        state: 'WARNING'
    }

    static ESTADO_CREDENCIALES_CADUCADOS = {
        icon: 'close-circle',
        color: '#eb2f96',
        state: 'ERROR'
    }

    static ESTADO_SIN_CREDENCIALES = {
        icon: 'minus-circle',
        color: '#1890ff',
        state: 'ERROR'
    }

    static icons_pending_contenedores = {
        'PENDIENTE': 'warning',
        'NO_PENDIENTE': 'container'
    }

    static colors_pending_monitoreo = {
        'PENDIENTE': '#ff0000d6',
        'NO_PENDIENTE': 'green'
    }

    static maxPesoUnidad = {
        'kg': 45000,
        'tn': 45,
        'lb': 99208,
    }

    static NOMBRES_RUBROS_COSTOS_REPORTE_COMERCIAL = [
        'ALQUILER CHASIS Y PLATAFORMAS CARGADOS PROPIOS',
        'SERVICIO LOGISTICO - HANDLING IN/OUT',
        'SERVICIO LOGISTICO - ALMACENAJE',
        'ALQUILER GENERADOR CARGADOS'
    ]

    //equivalente de 1 milla a km
    static milla_km = 1.60934

    static codigo_alfabeto_contenedor = [
        10,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        34,
        35,
        36,
        37,
        38,
    ]
    static peso_alfabeto_contenedor = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512]
    static dollarFormat = new Intl.NumberFormat('en-NZ', {
        style: 'currency',
        currency: 'NZD',
        minimumFractionDigits: 2,
    });


    static alphabet = ['A', 'B', 'C', 'D', 'E',
        'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O',
        'P', 'Q', 'R', 'S', 'T',
        'U', 'V', 'W', 'X', 'Y',
        'Z'];

    static colors = ['#028d09', '#16d3ff', '#022fff', '#a60034', '#02fd0c'];

    static colorRoute = ['red', 'blue', 'black', 'yellow', 'green'];

    static etiquetas_puerto_deposito = [
        {
            code: 'I',
            puerto: 'Retirar de',
            deposito: 'Entregar en'
        },
        {
            code: 'E',
            puerto: 'Entregar en',
            deposito: 'Retirar de'
        }
    ]

    static configPermisos({ authUser }) {
        const { role_code, company_type_code } = authUser;

        let permisos = {
            ingresoMaster: false,
            ingresoTransportista: false,
            ingresoImportador: false,
            ingresoExportador: false,
            ingresoCliente: false,
            ingresoAdministrador: false,
            ingresoForwarder: false,
            ingresoContador: false,
            ingresoConductor: false,
        }
        if (role_code == 'ADM_MASTER') {
            permisos.ingresoTransportista = true;
            permisos.ingresoMaster = true;
        }
        else {
            permisos.ingresoTransportista = (role_code == 'ADM_LOCAL' && company_type_code == 'TRANS') ? true : false;

            if (['EXP', 'IMP', 'CLT', 'FWDR'].indexOf(company_type_code) > -1) {
                permisos.ingresoExportador = (role_code == 'ADM_LOCAL') ? true : false;
                permisos.ingresoImportador = (role_code == 'ADM_LOCAL') ? true : false;
                permisos.ingresoCliente = (role_code == 'ADM_LOCAL') ? true : false;
                permisos.ingresoForwarder = (role_code == 'ADM_LOCAL' && company_type_code == 'FWDR') ? true : false;
            }

            permisos.ingresoContador = (role_code == 'ACCT' && (company_type_code == 'TRANS' || company_type_code == 'EXP' || company_type_code == 'IMP')) ? true : false;
            permisos.ingresoConductor = (role_code == 'DRIVER');
        }

        return permisos
    }

    static configPermisosReservar(authUser, id_company_current) {
        const { role_code, company_type_code } = authUser;

        let permisos = {
            ingresoForwarder: false,
            ingresoTransportista: true,
            ingresoFacturarA: false,
            ingresoDestinatario: false,
            defaultFacturarA: false,
            defaultDestinatario: false,
        }

        // Solo si es ADM_LOCAL de TRANSPORTISTA puede ingresar TRANSPORTISTA
        permisos.ingresoTransportista = (role_code == 'ADM_LOCAL' && company_type_code == 'TRANS') ? false : true;
        permisos.ingresoForwarder = (role_code == 'ADM_LOCAL' && company_type_code == 'FWDR') ? true : false;


        if (['FWDR', 'TRANS'].indexOf(company_type_code) > -1) {
            if (role_code == 'ADM_LOCAL') {
                permisos.ingresoFacturarA = true;
                permisos.ingresoDestinatario = true;
            }
        }

        if (['FWDR'].indexOf(company_type_code) > -1) {
            if (role_code == 'ADM_LOCAL') {
                permisos.defaultFacturarA = true;
                permisos.defaultDestinatario = true;
            }
        }



        return permisos
    }

    /**
     * Retorna las horas libres de un establecimiento en base a su codigo de establecimiento (PLT, PRT o DEP) y a su codigo de ciudad (GYE, MNT, etc)
     * @param {array<object>} lista_horas_libres Lista de todas las horas libres configuradas
     * @param {string} code_location
     * @param {string} code_canton
     */
    static horas_libres_establecimiento = (lista_horas_libres = [], code_location, code_canton) => {
        let horas_libres = 0
        let config_horas = lista_horas_libres.find(x => x.code == code_location)
        if (config_horas) {
            if (config_horas.list_locals) {
                let local = config_horas.list_locals.find(x => x.code_local == code_canton)
                horas_libres = local ? local.free_hours : config_horas.free_hours
            } else {
                horas_libres = config_horas.free_hours
            }
        }

        return horas_libres

    }

    static hours_time = () => {
        let array = []
        for (let index = 0; index < 24; index++) {
            array.push({
                text: (index < 10) ? `0${index}` : `${index}`,
                value: (index < 10) ? `0${index}` : `${index}`,
            })
        }
        return array
    }

    static formatSlot = (slot) => {
        const start = moment(slot.slotStart).format('YYYY-MM-DD HH:mm');
        const end = moment(slot.slotEnd).format('HH:mm');
        return `Fecha: ${moment(slot.slotStart).format('YYYY-MM-DD')} \n Horario: ${start.split(' ')[1]} - ${end}`;
    };

    static formatSlotLabel = (slot) => {
        const start = moment(slot.slotStart).format('YYYY-MM-DD HH:mm');
        const end = moment(slot.slotEnd).format('HH:mm');
        return `Horario: ${start.split(' ')[1]} - ${end}`;
    };

    static renderIconEstadoCrendenciales({ dias_restantes, credential_banned = false }) {

        if (credential_banned)
            return this.ESTADO_CREDENCIALES_BANNED


        if (dias_restantes > this.MAX_DIAS_ESTADO_CREDENCIALES)
            return this.ESTADO_CREDENCIALES_OK

        if (dias_restantes >= 0 && dias_restantes <= this.MAX_DIAS_ESTADO_CREDENCIALES)
            return this.ESTADO_CREDENCIALES_POR_CADUCAR

        if (dias_restantes < 0)
            return this.ESTADO_CREDENCIALES_CADUCADOS

        return this.ESTADO_SIN_CREDENCIALES
    }

    static renderEstadoCredencialBloqueado({ state_credential }) {
        if (state_credential == 'ACTIVE')
            return { label: 'Desbloqueado', state: false }

        if (state_credential == 'BANNED')
            return { label: 'Bloqueado', state: true }

        return { label: 'Desbloqueado', state: false }

    }

    static checkEstadoCredencialesChofer({ documentos = [], credenciales, licencias, deposito, cliente, puerto }) {
        if (!credenciales)
            return this.ESTADO_SIN_CREDENCIALES

        // const licencias_ok = licencias.every(x => x.dias_vencer > this.MAX_DIAS_ESTADO_CREDENCIALES)
        const licencias_por_vencer = licencias.some(x => x.dias_vencer >= 0 && x.dias_vencer <= this.MAX_DIAS_ESTADO_CREDENCIALES)
        const licencias_caducadas = licencias.some(x => x.dias_vencer < 0)
        if (licencias_caducadas)
            return this.ESTADO_CREDENCIALES_CADUCADOS

        if (licencias_por_vencer)
            return this.ESTADO_CREDENCIALES_POR_CADUCAR

        const existeCredencialPuerto = puerto ? credenciales.some(x => x.id_business_name == puerto.id_location && x.type_location == puerto.code) : true
        const existeCredencialCliente = cliente ? credenciales.some(x => x.id_business_name == cliente.id_company && x.type_location == cliente.code) : true
        const existeCredencialDeposito = deposito ? credenciales.some(x => x.id_business_name == deposito.id_location && x.type_location == deposito.code) : true

        if (existeCredencialPuerto && existeCredencialCliente && existeCredencialDeposito)
            return UtilsObj.ESTADO_CREDENCIALES_OK
        else
            return this.ESTADO_SIN_CREDENCIALES

        // if (licencias_ok)
        //     return UtilsObj.ESTADO_CREDENCIALES_OK


        return { color: null, icon: null }
    }

    static checkEstadoCredencialesCamion({ documentos }) {
        if (!documentos)
            return this.ESTADO_SIN_CREDENCIALES

        const credenciales_ok = documentos.every(x => x.dias_vencer > this.MAX_DIAS_ESTADO_CREDENCIALES)
        const credenciales_por_vencer = documentos.some(x => x.dias_vencer >= 0 && x.dias_vencer <= this.MAX_DIAS_ESTADO_CREDENCIALES)
        const credenciales_caducadas = documentos.some(x => x.dias_vencer < 0)
        if (credenciales_caducadas)
            return this.ESTADO_CREDENCIALES_CADUCADOS

        if (credenciales_por_vencer)
            return this.ESTADO_CREDENCIALES_POR_CADUCAR

        if (credenciales_ok)
            return UtilsObj.ESTADO_CREDENCIALES_OK


        return { color: null, icon: null }
    }

    static generarExcelBonos = (
        totales = [],
        detalles = [],
        excelFileName = '',
    ) => {
        // console.log(info)
        var wb = {}
        let columns_detalle_vacios = []
        let columns_detalle_viajes = []
        let columns_resumen_pagos = []
        let wscols_detalle_vacios = []
        let wscols_detalle_viajes = []
        let wscols_resumen_pagos = []


        columns_detalle_vacios.push(
            [
                'CI', //1
                'CHOFER', //2
                'RETIROS', //3
                'DEVOLUCIONES', //4
                'RETIROS + DEVOLUCIONES', //5
                'SUBTOTAL R+D', //6
                'SUBTOTAL VACIOS', //7
            ]
        )

        columns_detalle_viajes.push(
            [
                'CI', //1
                'CHOFER', //2
                'DISCO', //3
                'RESERVA', //4
                'FEC. PLANTA', //5
                'DESTINO', //6
                'RESCATE', //7
                'KM', //8
                'PORCENTAJE AVANCE', //9
                'PORCENTAJE FALSO FLETE', //10
                'ST BASE', //11
                'ESCALAFON', //12
                '% BONO', //13
                'DP', //14
                'TOTAL ST X VIAJE', //15
            ]
        )

        columns_resumen_pagos.push(
            [
                'CI', //1
                'CHOFER', //2
                'ESCALAFON', //3
                'SUBTOTAL VIAJES', //4
                'SUBTOTAL VACIOS', //5
                'TOTAL PAGO', //6
            ]
        )

        wscols_detalle_vacios = [
            { wpx: 50 }, //1
            { wpx: 120 }, //2
            { wpx: 50 }, //3
            { wpx: 50 }, //4
            { wpx: 50 }, //5
            { wpx: 50 }, //6
            { wpx: 50 }, //7
        ]

        wscols_detalle_viajes = [
            { wpx: 50 }, //1
            { wpx: 120 }, //2
            { wpx: 50 }, //3
            { wpx: 50 }, //4
            { wpx: 80 }, //5
            { wpx: 100 }, //6
            { wpx: 80 }, //7
            { wpx: 50 }, //8
            { wpx: 100 }, //9
            { wpx: 100 }, //10
            { wpx: 50 }, //11
            { wpx: 50 }, //12
            { wpx: 50 }, //13
            { wpx: 50 }, //14
            { wpx: 100 }, //15
        ]

        wscols_resumen_pagos = [
            { wpx: 50 }, //1
            { wpx: 120 }, //2
            { wpx: 50 }, //3
            { wpx: 80 }, //4
            { wpx: 80 }, //5
            { wpx: 80 }, //6
        ]



        wb = XLSX.utils.book_new()
        if (!wb.Props) wb.Props = {}
        wb.Props.Title = excelFileName
        wb.Props.Author = 'TPT'

        totales.map((tmp, i) => {
            let col_resumen = []
            col_resumen = [
                tmp.driver_identification_card,
                `${tmp.driver_last_name} ${tmp.driver_name}`,
                tmp.driver_escalafon,
                tmp.subtotal_viaje,
                tmp.subtotal_vacio,
                tmp.subtotal_viaje + tmp.subtotal_vacio
            ]

            columns_resumen_pagos[i + 1] = col_resumen
        })


        detalles.map((viaje, j) => {
            let col_viaje = []
            const { driver_identification_card, driver_last_name, driver_name, id_reservation, fecha_planta, destino, guide_rescue,
                km, porcentage_total_trip, false_flete, porcentage, dst_c50, dst_c100, driver_escalafon, driver_escalafon_percentage, st_base, costo_dp, disk } = viaje

            col_viaje = [
                driver_identification_card,
                `${driver_last_name} ${driver_name}`,
                disk,
                id_reservation,
                moment(fecha_planta).format(this.dateFormat9),
                destino,
                guide_rescue ? 'Si' : 'NO',
                km,
                porcentage_total_trip * 100,
                false_flete ? porcentage : null,
                parseFloat(dst_c50) + parseFloat(dst_c100),
                driver_escalafon,
                driver_escalafon_percentage * 100,
                costo_dp,
                parseFloat(st_base)
            ]

            columns_detalle_viajes[j + 1] = col_viaje
        })


        let worksheet_detalle_vacios = {}
        let worksheet_detalle_viajes = {}
        let worksheet_resumen_pagos = {}


        worksheet_detalle_viajes = XLSX.utils.aoa_to_sheet(columns_detalle_viajes)
        worksheet_detalle_viajes['!cols'] = wscols_detalle_viajes

        worksheet_resumen_pagos = XLSX.utils.aoa_to_sheet(columns_resumen_pagos)
        worksheet_resumen_pagos['!cols'] = wscols_resumen_pagos



        // XLSX.utils.book_append_sheet(wb, worksheet_detalle_vacios, 'DETALLE_VACIOS')
        XLSX.utils.book_append_sheet(wb, worksheet_detalle_viajes, 'DETALLE_VIAJES')
        XLSX.utils.book_append_sheet(wb, worksheet_resumen_pagos, 'RESUMEN_PAGOS')

        /* generate XLSX file and send to client */
        let name_file = `${excelFileName}_${moment().format('YYYYMMDDHHmm')}.xls`
        XLSX.writeFile(wb, name_file, { compression: true })
        return true
    }

    static copyTextToClipboard = async ({ link }) => {
        try {
            await navigator.clipboard.writeText(link).then(function () {
                message.success('Enlace copiado en portapapeles')
            }, function (err) {
                message.error('Ha ocurrido un error al generar enlace', err);
            });
        } catch (e) {
            message.error('Ha ocurrido un error al generar enlace', e.message);
        }
    }

    static generarExcelReporteAsesores = (
        {
            info = [],
            sheetName = '',
            excelFileName = '',
            rango_consulta = {
                inicio: '',
                fin: ''
            }
        }
    ) => {
        const EXCEL_EXTENSION = '.xlsx'
        let wb = {}
        const columns = []
        let wscols = []

        columns.push(
            [
                'Asesor', //1
                'Fecha Planta',//2
                'Mes', //3
                'Semana',//4
                'Día Semana',//5
                'Reserva',//6
                'Cliente', //7
                'Generador',//8
                'Facturado Total', //9
                'Facturado Generador', //10
                'Tarifa', //11
                'Puerto', //12
                'Puerto Ciudad', //13
                'Planta Ciudad', //14
                'Costo', //15
                'Chasis', //16
                'Handling In-Out', //17
                'Almacenaje', //18
                'Generador', //19
                'Otros',//20
                'Estado Facturación', //21
                'Flota', //22
                'Contribución',//23
            ]
        )

        wscols = [
            { wpx: 120 }, //1
            { wpx: 70 }, //2
            { wpx: 50 }, //3
            { wpx: 50 }, //4
            { wpx: 50 }, //5
            { wpx: 50 }, //6
            { wpx: 120 }, //7
            { wpx: 50 }, //8
            { wpx: 80 }, //9
            { wpx: 80 }, //10
            { wpx: 80 }, //11
            { wpx: 100 }, //12
            { wpx: 100 }, //13
            { wpx: 100 }, //14
            { wpx: 50 }, //15
            { wpx: 50 }, //16
            { wpx: 50 }, //17
            { wpx: 50 }, //18
            { wpx: 50 }, //19
            { wpx: 50 }, //20
            { wpx: 80 }, //21
            { wpx: 80 }, //22
            { wpx: 50 }, //23
        ]


        wb = XLSX.utils.book_new()
        if (!wb.Props) wb.Props = {}
        wb.Props.Title = excelFileName
        wb.Props.Author = 'TPT'

        info.map((tmp, j) => {
            let col = []

            const { asesor, cliente, tarifa, ruta, camion, facturado, mes, semana, dia_semana, generador, costo_generador, id_reservation,
                costo_particular, fecha_reserva, costo_particular_enviado, costo_particular_registrado, parametros_costos_tpt_propios } = tmp

            const detalle_facturado = facturado ?? []
            const puerto = ruta.find(x => x.tipo_establecimiento == 'PRT')
            const planta = ruta.find(x => x.tipo_establecimiento == 'PLT')

            const nombre_puerto = puerto ? puerto.nombre_establecimiento : ''
            const ciudad_puerto = puerto ? puerto.ciudad_establecimiento : ''


            const ciudad_planta = planta ? planta.ciudad_establecimiento : ''

            const camion_guia = camion ? camion[0] : null

            const flota = camion ? camion_guia.camion_propio ? 'PROPIO' : 'PARTICULAR' : ''

            const facturas_generador = detalle_facturado.filter(x => (x.description.toLowerCase().includes('generador')))
            const generador_facturado = facturas_generador.reduce((acc, x) => acc + parseFloat(x.subtotal) + parseFloat(x.iva), 0).toFixed(2)

            const total_facturado = detalle_facturado.reduce((acc, x) => acc + parseFloat(x.subtotal) + parseFloat(x.iva), 0).toFixed(2)


            const costo_tarifa = tarifa ? parseFloat(tarifa) : 0
            let total_costo = 0

            if (camion_guia && camion_guia.camion_propio) {
                total_costo = UtilsObj.calculoCostosTPTPropios({ parametros_costos_tpt_propios, rubros_costos_reserva: costo_particular_enviado || costo_particular_registrado })
            } else {
                if (costo_particular_enviado && costo_particular_enviado.length != 0)
                    total_costo = costo_particular_enviado.reduce((acc, x) => acc + parseFloat(x.subtotal) + parseFloat(x.iva), 0)


                if (costo_particular_registrado && costo_particular_registrado.length != 0)
                    total_costo = costo_particular_registrado.reduce((acc, x) => acc + parseFloat(x.subtotal) + parseFloat(x.iva), 0)

            }

            const chasis = UtilsObj.calculoRubrosCostosCategoria({ rubros_costos_reserva: costo_particular_enviado || costo_particular_registrado, rubroNombre: 'ALQUILER CHASIS Y PLATAFORMAS CARGADOS PROPIOS' })
            const handling = UtilsObj.calculoRubrosCostosCategoria({ rubros_costos_reserva: costo_particular_enviado || costo_particular_registrado, rubroNombre: 'SERVICIO LOGISTICO - HANDLING IN/OUT' })
            const almacenaje = UtilsObj.calculoRubrosCostosCategoria({ rubros_costos_reserva: costo_particular_enviado || costo_particular_registrado, rubroNombre: 'SERVICIO LOGISTICO - ALMACENAJE' })
            const generador_costo = UtilsObj.calculoRubrosCostosCategoria({ rubros_costos_reserva: costo_particular_enviado || costo_particular_registrado, rubroNombre: 'ALQUILER GENERADOR CARGADOS' })
            const otros_costo = UtilsObj.calculoRubrosCostosCategoria({ rubros_costos_reserva: costo_particular_enviado || costo_particular_registrado, rubroNombre: 'OTROS' })


            const contribucion = parseFloat((parseFloat(total_facturado) - parseFloat(total_costo)))
            const estados_facturacion = {
                'ENVIADO': 'ENVIADO',
                'PENDIENTE': 'PENDIENTE',
                'VENTA_ENVIADA': 'VENTA ENVIADA',
                'COSTO_ENVIADO': 'COSTO ENVIADO'
            }

            let estado_facturacion = estados_facturacion.PENDIENTE

            const VENTA_ENVIADA_SAP = facturado && facturado.length > 0
            const COSTO_ENVIADO_SAP = costo_particular_enviado && costo_particular_enviado.subtotal != 0

            if (VENTA_ENVIADA_SAP && COSTO_ENVIADO_SAP) estado_facturacion = estados_facturacion.ENVIADO
            if (!VENTA_ENVIADA_SAP && !COSTO_ENVIADO_SAP) estado_facturacion = estados_facturacion.PENDIENTE
            if (VENTA_ENVIADA_SAP && !COSTO_ENVIADO_SAP) estado_facturacion = estados_facturacion.VENTA_ENVIADA
            if (!VENTA_ENVIADA_SAP && COSTO_ENVIADO_SAP) estado_facturacion = estados_facturacion.COSTO_ENVIADO
            // if (camion_guia && camion_guia.camion_propio) estado_facturacion = estados_facturacion.ENVIADO


            col = [
                asesor,
                fecha_reserva,
                mes,
                semana,
                dia_semana,
                id_reservation,
                cliente,
                generador,
                total_facturado,
                generador_facturado,
                costo_tarifa,
                nombre_puerto,
                ciudad_puerto,
                ciudad_planta,
                total_costo,
                chasis,
                handling,
                almacenaje,
                generador_costo,
                otros_costo,
                estado_facturacion,
                flota,
                contribucion,
            ]

            columns[j + 1] = col

        })


        // columnas_excel.push(data)
        let worksheet = {}
        const new_columns = [[`Total Registros: ${info.length}`, '', '', `Consulta de ${rango_consulta.inicio} hasta ${rango_consulta.fin}`]].concat(columns)
        worksheet = XLSX.utils.aoa_to_sheet(new_columns)
        worksheet['!cols'] = wscols
        worksheet['!merges'] = [
            { s: { r: 0, c: 3 }, e: { r: 0, c: 8 } }
        ]

        XLSX.utils.book_append_sheet(wb, worksheet, sheetName)
        /* generate XLSX file and send to client */
        let name_file = `${excelFileName}_${moment().format('YYYYMMDDHHmm')}${EXCEL_EXTENSION}`
        XLSX.writeFile(wb, name_file, { compression: true })
        return true
    }

    static generarExcelReporteReservas = (
        info = [],
        sheetName = '',
        excelFileName = '',
    ) => {
        const EXCEL_EXTENSION = '.xlsx'
        var wb = {}
        let columns = []
        var wscols = []

        columns.push(
            [
                'Reserva', //1
                'Operativo', //2
                'Cliente', //3
                'Destinatario', //4
                'Planta', //5
                'Fecha Planta', //6
                'Semana', //7
                'Dia semana', //8
                'Cabezal', //9
                'Placa', //10
                'Propio_particular', //11
                'Tipo Cabezal', //12
                'Generador', //13
                'Chasis', //14
                'Tipo Contenedor',//15
                'Tipo Contenedor Categoria',//16
                'Contenedor', //17
                'Contenedor Devuelto', //18
                'Res. Cont. Devuelto', //19
                'Peso (Tn)', //20
                'Tipo', //21
                'Producto',//22
                'Booking', // 23
                'BL', //24
                'Naviera', //25
                'Voyage', //26
                'Reenganche', //27
                'Contenedor DPW', //28
                'Ruta', //29
                'Ciudad', //30
                'Estado', //31
                'Depósito', //32
                'Fec_llegada_deposito', //33
                'Fec_salida_deposito', //34
                'Fec_llegada_planta', //35
                'Fec_salida_planta', //36
                'Fec_salida_patio', //37
                'Fec_retorno_patio', //38
                'Puerto', //39
                'Fec_llegada_puerto', //40
                'Fec_salida_puerto', //41
                'Fec_cierre', //42
                'Galones', //43
                'Total Pago Viaje', //44
                'Total Pago Viaje Rescate', //45
                'Viaticos', //46
                'Peaje', //47
                'Distancia (km)', //48
                'Chofer', //49
                'Transportista',//50
                'Transportista Operador',//51
                'Facturar', //52
                'Referencia', //53
                'Observaciones', //54
                'Subtotal_Facturado', //55
                'Iva_Facturado', //56
            ]
        )

        wscols = [
            { wpx: 50 }, //1
            { wpx: 120 }, //2
            { wpx: 120 }, //3
            { wpx: 200 }, //4
            { wpx: 130 }, //5
            { wpx: 50 }, //6
            { wpx: 50 }, //7
            { wpx: 50 }, //8
            { wpx: 50 }, //9
            { wpx: 70 }, //10
            { wpx: 100 }, //11
            { wpx: 50 }, //12
            { wpx: 50 }, //13
            { wpx: 50 }, //14
            { wpx: 100 }, //15
            { wpx: 100 }, //16
            { wpx: 100 }, //17
            { wpx: 100 }, //18
            { wpx: 50 }, //19
            { wpx: 100 }, //20
            { wpx: 100 }, //21
            { wpx: 80 }, //22
            { wpx: 80 }, //23
            { wpx: 120 }, //24
            { wpx: 120 }, //25
            { wpx: 80 }, //26
            { wpx: 80 }, //27
            { wpx: 250 }, //28
            { wpx: 120 }, //29
            { wpx: 100 }, //30
            { wpx: 100 }, //31
            { wpx: 120 }, //32
            { wpx: 120 }, //33
            { wpx: 120 }, //34
            { wpx: 120 }, //35
            { wpx: 120 }, //36
            { wpx: 120 }, //37
            { wpx: 120 }, //38
            { wpx: 120 }, //39
            { wpx: 120 }, //40
            { wpx: 120 }, //41
            { wpx: 80 }, //42
            { wpx: 80 }, //43
            { wpx: 100 }, //44
            { wpx: 100 }, //45
            { wpx: 80 }, //46
            { wpx: 80 }, //47
            { wpx: 180 }, //48
            { wpx: 120 }, //49
            { wpx: 120 }, //50
            { wpx: 120 }, //51
            { wpx: 120 }, //52
            { wpx: 120 }, //53
            { wpx: 150 }, //54
            { wpx: 120 }, //55
            { wpx: 120 }, //56
        ]


        wb = XLSX.utils.book_new()
        if (!wb.Props) wb.Props = {}
        wb.Props.Title = excelFileName
        wb.Props.Author = 'TPT'


        info.map((tmp, j) => {
            let col = []

            const { recorrido, equipo_operativo_usuarios,
                reserva, cliente, planta, fecha_planta, semana, dia_semana, cabezal, placa, propio_particular, tipo_cabezal,
                generador, chasis, tipo_contenedor, tipo_contenedor_descripcion, contenedor, peso, tipo, producto, booking, bl, naviera,
                voyage, reenganche, contenedor_dpw, ruta, estado, fecha_salida_patio, fecha_retorno_patio, fecha_cierre, galones, travel_expense,
                peaje, distance, transportista, transportista_operadora, facturar, referencia, observaciones, destinatario, factura_subtotal, factura_iva, deposito,
                retiro, contenedor_devuelto, total_pago_viaje_rescate, total_pago_viaje } = tmp
            const { reservation_id, container_code } = contenedor_devuelto ? contenedor_devuelto : {}
            const container_code_devuelto = container_code ? container_code : '-'
            const container_code_devuelto_reserva = reservation_id ? reservation_id : '-'

            let planta_recorrido = null
            let deposito_recorrido = null
            let puerto_recorrido = null

            if (recorrido && recorrido.length) {
                puerto_recorrido = recorrido.find(x => x.code == 'PRT')
                deposito_recorrido = recorrido.find(x => x.code == 'DEP')
                planta_recorrido = recorrido.find(x => x.code == 'PLT')
            }

            if (retiro && retiro.length) {
                if (!puerto_recorrido) puerto_recorrido = retiro.find(x => x.code == 'PRT')
                if (!deposito_recorrido) deposito_recorrido = retiro.find(x => x.code == 'DEP')
            }

            const usuarios_operativos = equipo_operativo_usuarios ? equipo_operativo_usuarios.map(x => x.nombre_operativo).join(',') : '-'

            col = [
                reserva,
                usuarios_operativos,
                cliente,
                destinatario,
                planta,
                fecha_planta,
                semana,
                dia_semana,
                cabezal,
                placa,
                propio_particular,
                tipo_cabezal,
                generador,
                chasis,
                tipo_contenedor,
                tipo_contenedor_descripcion,
                contenedor,
                container_code_devuelto,
                container_code_devuelto_reserva,
                peso,
                tipo,
                producto,
                booking,
                bl,
                naviera,
                voyage,
                reenganche,
                contenedor_dpw,
                ruta,
                planta_recorrido && planta_recorrido.ciudad ? planta_recorrido.ciudad : '',
                estado,
                deposito && deposito.location_name ? deposito.location_name : '',
                deposito_recorrido && deposito_recorrido.date_location ? deposito_recorrido.date_location : '',
                deposito_recorrido && deposito_recorrido.date_init ? deposito_recorrido.date_init : '',
                planta_recorrido && planta_recorrido.date_location ? planta_recorrido.date_location : '',
                planta_recorrido && planta_recorrido.date_init ? planta_recorrido.date_init : '',
                fecha_salida_patio,
                fecha_retorno_patio,
                puerto_recorrido && puerto_recorrido.location_name ? puerto_recorrido.location_name : '',
                puerto_recorrido && puerto_recorrido.date_location ? puerto_recorrido.date_location : '',
                puerto_recorrido && puerto_recorrido.date_init ? puerto_recorrido.date_init : '',
                fecha_cierre,
                galones,
                total_pago_viaje,
                total_pago_viaje_rescate,
                travel_expense,
                peaje,
                distance,
                planta_recorrido && planta_recorrido.chofer ? planta_recorrido.chofer : '',
                transportista,
                transportista_operadora,
                facturar,
                referencia,
                observaciones,
                factura_subtotal,
                factura_iva
            ]

            columns[j + 1] = col

        })


        // columnas_excel.push(data)
        let worksheet = {}
        worksheet = XLSX.utils.aoa_to_sheet(columns)
        worksheet['!cols'] = wscols
        // worksheet['!merges'] = [
        //     { s: { r: 0, c: 25 }, e: { r: 0, c: 29 } },
        //     { s: { r: 0, c: 30 }, e: { r: 0, c: 34 } },
        //     { s: { r: 0, c: 35 }, e: { r: 0, c: 39 } },
        // ]

        XLSX.utils.book_append_sheet(wb, worksheet, sheetName)
        /* generate XLSX file and send to client */
        let name_file = `${excelFileName}_${moment().format('YYYYMMDDHHmm')}${EXCEL_EXTENSION}`
        XLSX.writeFile(wb, name_file, { compression: true })
        return true
    }

    /**
     * Descarga un excel de reporte de particulares
   */
    static generarExcelParticulares = (
        info = [],
        excel_totales = true,
        sheetName = '',
        excelFileName = '',
    ) => {
        const EXCEL_EXTENSION = '.xlsx'
        var wb = {}
        let columns = []
        var wscols = []
        if (excel_totales) {
            columns.push(
                [
                    'Transportista', //1
                    'Ruc', //2
                    'Anticipo', //3
                ]
            )

            wscols = [
                { wpx: 250 }, //1
                { wpx: 120 }, //2
                { wpx: 50 } //3
            ]

        } else {
            columns.push(
                [
                    'Transportista', //1
                    'Ruc', //2
                    'Apellidos', //3
                    'Nombres', //4
                    'Reserva', //5
                    'Guía', //6
                    'Estado',//7
                    'Ruta', //8
                    'Destinatario', //9
                    'Fecha en planta', //10
                    'Tipo', //11
                    'Contenedor', //12
                    'Booking', //13
                    'Tipo Guia', //14
                    'Rescate', // 15
                    'Viático', //16
                    'Peaje', //17
                    'Gal. Ruta', //18
                    'Gal. Retiro', //19
                    'Anticipo', //20
                ]
            )

            wscols = [
                { wpx: 250 }, //1
                { wpx: 120 }, //2
                { wpx: 130 }, //3
                { wpx: 130 }, //4
                { wpx: 50 }, //5
                { wpx: 50 }, //6
                { wpx: 50 }, //7
                { wpx: 200 }, //8
                { wpx: 200 }, //9
                { wpx: 120 }, //10
                { wpx: 100 }, //11
                { wpx: 80 }, //12
                { wpx: 80 }, //13
                { wpx: 80 }, //14
                { wpx: 80 }, //15
                { wpx: 50 }, //16
                { wpx: 50 }, //17
                { wpx: 50 }, //18
                { wpx: 50 }, //19
                { wpx: 50 }, //20
            ]

        }

        wb = XLSX.utils.book_new()
        if (!wb.Props) wb.Props = {}
        wb.Props.Title = excelFileName
        wb.Props.Author = 'TPT'


        info.map((tmp, j) => {
            let col = []
            if (excel_totales) { // para excel de choferes solo totales
                // let total = 0

                col = [
                    tmp.company_name,
                    tmp.company_ruc,
                    parseFloat(tmp.anticipo),
                ]

                columns[j + 1] = col


            } else {// para excel de choferes con detalle de reserva realizada

                col = [
                    tmp.company_name,
                    tmp.company_ruc,
                    tmp.driver_last_name,
                    tmp.driver_name,
                    tmp.id_reservation,
                    tmp.id_reservation_milestone_guide,
                    tmp.state,
                    tmp.ruta,
                    tmp.company_addressee_name,
                    moment(`${tmp.appointment_date} ${tmp.appointment_time}`, this.timeFormat1).format(this.dateFormat2),
                    tmp.type_name,
                    tmp.container_code,
                    tmp.booking,
                    tmp.type_guide,
                    tmp.guide_rescue ? 'SI' : 'NO',
                    parseFloat(tmp.travel_expense),
                    parseFloat(tmp.peaje),
                    parseFloat(tmp.gal_ruta),
                    parseFloat(tmp.gal_retiro),
                    parseFloat(tmp.anticipo)
                ]

                columns[j + 1] = col
            }
        })


        // columnas_excel.push(data)
        let worksheet = {}
        worksheet = XLSX.utils.aoa_to_sheet(columns)
        worksheet['!cols'] = wscols
        // worksheet['!merges'] = [
        //     { s: { r: 0, c: 25 }, e: { r: 0, c: 29 } },
        //     { s: { r: 0, c: 30 }, e: { r: 0, c: 34 } },
        //     { s: { r: 0, c: 35 }, e: { r: 0, c: 39 } },
        // ]

        XLSX.utils.book_append_sheet(wb, worksheet, sheetName)
        /* generate XLSX file and send to client */
        let name_file = `${excelFileName}_${moment().format('YYYYMMDDHHmm')}${EXCEL_EXTENSION}`
        XLSX.writeFile(wb, name_file, { compression: true })
        return true
    }

    static generarReporteReservasTransportistaParticular = (
        info = [],
        sheetName = '',
        excelFileName = '',) => {
        const EXCEL_EXTENSION = '.xlsx'
        var wb = {}
        let columns = []
        var wscols = []

        columns.push(
            [
                'Reserva', //1
                'Tipo', //2
                'Contenedor', //3
                'Planta', //4
                'Fecha', //5
                'Naviera', //6
                'Retirar de', //7
                'Entregar en', //8
                'Transportista', //9
                'Placa', //10
                'Conductor', //11
                'Rubro', //12
                'Costo', //13
                'Estado', //14
            ]
        )

        wscols = [
            { wpx: 80 }, //1
            { wpx: 80 }, //2
            { wpx: 80 }, //3
            { wpx: 120 }, //4
            { wpx: 120 }, //5
            { wpx: 100 }, //6
            { wpx: 100 }, //7
            { wpx: 100 }, //8
            { wpx: 140 }, //9
            { wpx: 80 }, //10
            { wpx: 150 }, //11
            { wpx: 150 }, //12
            { wpx: 80 }, //13
            { wpx: 80 }, //14
        ]


        wb = XLSX.utils.book_new()
        if (!wb.Props) wb.Props = {}
        wb.Props.Title = excelFileName
        wb.Props.Author = 'TPT'

        info.map((reserva, j) => {
            const { recorrido, id_reservation, reservation_type, reservation_type_name, container_code, shipping_line, appointment_date,
                guias, rubro, costo, estado
            } = reserva

            const planta = recorrido.find(r => r.code === 'PLT')
            const nombre_planta = planta && planta.location_name ? planta.location_name : '-'

            const code_retirar_de = reservation_type === 'E' ? 'DEP' : 'PRT'
            const retirar_de = recorrido.find(r => r.code === code_retirar_de)
            const nombre_retirar_de = retirar_de && retirar_de.location_name ? retirar_de.location_name : '-'

            const code_entregar_en = reservation_type === 'E' ? 'PRT' : 'DEP'
            const entregar_en = recorrido.find(r => r.code === code_entregar_en)
            const nombre_entregar_en = entregar_en && entregar_en.location_name ? entregar_en.location_name : '-'

            const guia = guias[0]
            const nombre_transportista = guia && guia.company_truck_transport ? guia.company_truck_transport : '-'
            const numero_placa = guia && guia.license_plate ? guia.license_plate : '-'
            const nombre_conductor = guia && guia.driver_name ? guia.driver_name : '-'

            const col = [
                id_reservation,
                reservation_type_name,
                container_code,
                nombre_planta,
                moment(appointment_date, this.timeFormat1).format(this.dateFormat8),
                shipping_line,
                nombre_retirar_de,
                nombre_entregar_en,
                nombre_transportista,
                numero_placa,
                nombre_conductor,
                rubro,
                costo,
                estado
            ]

            columns[j + 1] = col

        })


        let worksheet = {}
        worksheet = XLSX.utils.aoa_to_sheet(columns)
        worksheet['!cols'] = wscols

        XLSX.utils.book_append_sheet(wb, worksheet, sheetName)
        /* generate XLSX file and send to client */
        let name_file = `${excelFileName}_${moment().format('YYYYMMDDHHmm')}${EXCEL_EXTENSION}`

        XLSX.writeFile(wb, name_file, { compression: true })
        return true
    }

    /**
      * Descarga un excel de reporte de los viaticos conductores
    */
    static generarExcelViaticosConductores = (
        info = [],
        excel_totales = true,
        sheetName = '',
        excelFileName = '',
    ) => {
        const EXCEL_EXTENSION = '.xlsx'
        var wb = {}
        let columns = []
        var wscols = []
        if (excel_totales) {
            columns.push(
                [
                    'Cédula', //1
                    'Apellidos', //2
                    'Nombres', //3
                    'Escalafon', //4
                    'Viático', //5
                ]
            )

            wscols = [
                { wpx: 120 }, //1
                { wpx: 150 }, //2
                { wpx: 150 }, //3
                { wpx: 50 }, //4
                { wpx: 50 } //5
            ]

        } else {
            columns.push(
                [
                    'Cédula', //1
                    'Apellidos', //2
                    'Nombres', //3
                    'Escalafon', //4
                    'Reserva', //5
                    'Guía', //6
                    'Ruta', //7
                    'Destinatario', //8
                    'Fecha en planta', //9
                    'Tipo', //10
                    'Contenedor', //11
                    'Booking', //12
                    'Tipo Guia', //13
                    'Rescate', // 14
                    'Viático', //15
                ]
            )

            wscols = [
                { wpx: 100 }, //1
                { wpx: 130 }, //2
                { wpx: 130 }, //3
                { wpx: 50 }, //4
                { wpx: 50 }, //5
                { wpx: 50 }, //6
                { wpx: 200 }, //7
                { wpx: 120 }, //8
                { wpx: 100 }, //9
                { wpx: 80 }, //10
                { wpx: 80 }, //11
                { wpx: 80 }, //12
                { wpx: 80 }, //13
                { wpx: 50 }, //14
                { wpx: 50 }, //15
            ]
        }


        wb = XLSX.utils.book_new()
        if (!wb.Props) wb.Props = {}
        wb.Props.Title = excelFileName
        wb.Props.Author = 'TPT'


        info.map((conductor, j) => {
            let col = []
            if (excel_totales) { // para excel de choferes solo totales
                // let total = 0

                // conductor.reservas.map((reserva, i) => {
                //     total += reserva.travel_expense_fee ? (parseFloat(reserva.travel_expense_fee)) : (parseFloat(reserva.travel_expense_city))
                // })

                col = [
                    conductor.driver_identification_card,
                    conductor.driver_last_name,
                    conductor.driver_name,
                    conductor.driver_escalafon,
                    conductor.viatico ? parseFloat(conductor.viatico) : 0
                ]

                columns[j + 1] = col


            } else {// para excel de choferes con detalle de reserva realizada
                let ruta = ''

                if (conductor.trip_stop)
                    conductor.trip_stop.map((trip, index) => {
                        ruta += (index != conductor.trip_stop.length - 1) ? `${trip.canton_name}-` : `${trip.canton_name}`
                    })

                col = [
                    conductor.driver_identification_card,
                    conductor.driver_last_name,
                    conductor.driver_name,
                    conductor.driver_escalafon,
                    conductor.id_reservation,
                    conductor.id_reservation_milestone_guide,
                    ruta,
                    conductor.company_addressee_name,
                    moment(`${conductor.appointment_date} ${conductor.appointment_time}`, this.timeFormat1).format(this.dateFormat2),
                    conductor.type_name,
                    conductor.container_code,
                    conductor.booking,
                    conductor.type_guide,
                    conductor.guide_rescue ? 'SI' : 'NO',
                    conductor.viatico ? parseFloat(conductor.viatico) : 0
                ]

                columns[j + 1] = col
            }
        })


        // columnas_excel.push(data)
        let worksheet = {}
        worksheet = XLSX.utils.aoa_to_sheet(columns)
        worksheet['!cols'] = wscols
        // worksheet['!merges'] = [
        //     { s: { r: 0, c: 25 }, e: { r: 0, c: 29 } },
        //     { s: { r: 0, c: 30 }, e: { r: 0, c: 34 } },
        //     { s: { r: 0, c: 35 }, e: { r: 0, c: 39 } },
        // ]

        XLSX.utils.book_append_sheet(wb, worksheet, sheetName)
        /* generate XLSX file and send to client */
        let name_file = `${excelFileName}_${moment().format('YYYYMMDDHHmm')}${EXCEL_EXTENSION}`

        XLSX.writeFile(wb, name_file, { compression: true })
        return true
    }

    static segundosHorasMinutos(seconds) {
        if (seconds == 0)
            return 0
        var hour = Math.floor(seconds / 3600);
        hour = (hour < 10) ? '0' + hour : hour;
        var minute = Math.floor((seconds / 60) % 60);
        minute = (minute < 10) ? '0' + minute : minute;
        // var second = seconds % 60;
        // second = (second < 10) ? '0' + second : second;
        return `${hour}h ${minute}m`
    }

    static milisegundosHorasMinutosSegundos(milisegundos) {
        let seconds = parseInt((milisegundos / 1000) % 60)
        let minutes = parseInt((milisegundos / (1000 * 60)) % 60)
        let hours = parseInt((milisegundos / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return `${hours}:${minutes}:${seconds}`
    }

    static milisegundosHorasMinutos(milisegundos) {
        // let seconds = parseInt((milisegundos / 1000) % 60)
        let minutes = parseInt((milisegundos / (1000 * 60)) % 60)
        let hours = parseInt((milisegundos / (1000 * 60 * 60)) % 24);

        hours = (hours < 10 && hours >= 0) ? "0" + hours : hours;
        minutes = (minutes < 10 && hours >= 0) ? "0" + minutes : minutes;
        // seconds = (seconds < 10) ? "0" + seconds : seconds;

        return `${hours}:${minutes}`
    }

    static estados(estado) {
        let comp = null;

        if (!estado)
            return <Tag color="red" className='column-tag'>{'Sin Estado'}</Tag>

        switch (estado) {
            case 'GUIA_NO_GENERADA':
                comp = <Tag color="#faad14" className='column-tag'>Guía no generada</Tag>
                break;
            case 'PENDING':
                comp = <Tag color="blue" className='column-tag'>Pendiente</Tag>
                break;
            case 'FINISHED':
                comp = <Tag color="gray" className='column-tag'>Finalizado</Tag>
                break;
            case 'IN_PROGRESS':
                comp = <Tag color="green" className='column-tag'>En Curso</Tag>
                break;
            case 'ACCEPTED':
                comp = <Tag color="green" className='column-tag'>Aceptado</Tag>
                break;
            case 'REJECTED':
                comp = <Tag color="red" className='column-tag'>Rechazado</Tag>
                break;
            case 'NOT_EJECUTED':
                comp = <Tag color="red" className='column-tag'>No Realizado</Tag>
                break;
            case 'RETIRO_POR_SALIR':
                comp = <Tag color="blue" className='column-tag'>Retiro por salir</Tag>
                break;
            case 'DEVOLUCION_POR_SALIR':
                comp = <Tag color="blue" className='column-tag'>Devolución por salir</Tag>
                break;
            case 'VIAJE_POR_SALIR':
                comp = <Tag color="blue" className='column-tag'>Viaje por salir</Tag>
                break;
            case 'VIAJANDO':
                comp = <Tag color="green" className='column-tag'>Viajando</Tag>
                break;
            case 'VIAJE_POR_CERRAR':
                comp = <Tag color="blue" className='column-tag'>Viaje por cerrar</Tag>
                break;
            case 'DEVOLUCION_POR_CERRAR':
                comp = <Tag color="blue" className='column-tag'>Devolución por cerrar</Tag>
                break;
            case 'VIAJE_CERRADO':
                comp = <Tag color="gray" className='column-tag'>Viaje cerrado</Tag>
                break;
            case 'RETIRO_CERRADO':
                comp = <Tag color="gray" className='column-tag'>Retiro cerrado</Tag>
                break;
            case 'DEVOLUCION_CERRADO':
                comp = <Tag color="gray" className='column-tag'>Devolución cerrado</Tag>
                break;
            case 'RETIRANDO':
                comp = <Tag color="blue" className='column-tag'>Retirando</Tag>
                break;
            case 'EN_DEPOSITO':
                comp = <Tag color="blue" className='column-tag'>En depósito</Tag>
                break;
            case 'RETIRADO':
                comp = <Tag color="blue" className='column-tag'>Retirado</Tag>
                break;
            case 'RETIRO_POR_CERRAR':
                comp = <Tag color="blue" className='column-tag'>Retiro por cerrar</Tag>
                break;
            case 'GUIA_PENDIENTE':
                comp = <Tag color="#faad14" className='column-tag'>Guía pendiente</Tag>
                break;
            case 'EN_PLANTA':
                comp = <Tag color="blue" className='column-tag'>En planta</Tag>
                break;
            case 'EN_PUERTO':
                comp = <Tag color="blue" className='column-tag'>En puerto</Tag>
                break;
            case 'GUIA_RESCATADA':
                comp = <Tag color="gold" className='column-tag'>Guia Rescatada</Tag>
                break;
            default:
                comp = <Tag color="blue" className='column-tag'>{estado}</Tag>
        }

        return comp
    }

    static estadosForFilter = [
        { text: 'Guía no generada', value: 'GUIA_NO_GENERADA' },
        { text: 'Pendiente', value: 'PENDING' },
        { text: 'Finalizado', value: 'FINISHED' },
        { text: 'En Curso', value: 'IN_PROGRESS' },
        { text: 'Aceptado', value: 'ACCEPTED' },
        { text: 'Rechazado', value: 'REJECTED' },
        { text: 'No Realizado', value: 'NOT_EJECUTED' },
        { text: 'Retiro por salir', value: 'RETIRO_POR_SALIR' },
        { text: 'Devolución por salir', value: 'DEVOLUCION_POR_SALIR' },
        { text: 'Viaje por salir', value: 'VIAJE_POR_SALIR' },
        { text: 'Viajando', value: 'VIAJANDO' },
        { text: 'Viaje por cerrar', value: 'VIAJE_POR_CERRAR' },
        { text: 'Devolución por cerrar', value: 'DEVOLUCION_POR_CERRAR' },
        { text: 'Viaje cerrado', value: 'VIAJE_CERRADO' },
        { text: 'Retiro cerrado', value: 'RETIRO_CERRADO' },
        { text: 'Devolución cerrado', value: 'DEVOLUCION_CERRADO' },
        { text: 'Retirando', value: 'RETIRANDO' },
        { text: 'En depósito', value: 'EN_DEPOSITO' },
        { text: 'Retirado', value: 'RETIRADO' },
        { text: 'Retiro por cerrar', value: 'RETIRO_POR_CERRAR' },
        { text: 'Guía pendiente', value: 'GUIA_PENDIENTE' },
        { text: 'En planta', value: 'EN_PLANTA' },
        { text: 'En puerto', value: 'EN_PUERTO' },
        { text: 'Guia Rescatada', value: 'GUIA_RESCATADA' }
    ]

    static configPermisosEstablecimientos(authUser, location) {
        const { role_code, id_company, company_type_code } = authUser;
        const { code, code_company_type } = location ? location : {};
        const id_company_location = location ? location.id_company : null;

        let permisos = {
            editarEstablecimiento: false,
            eliminarEstablecimiento: false,
            registroEstablecimiento: true,
            seleccionarCompania: false
        }

        // if (id_company_location == 2537) {
        //     // console.log(id_company_location, id_company)
        // }

        if (id_company == id_company_location) { // En edición
            if (role_code == 'ADM_LOCAL') {
                permisos.editarEstablecimiento = true;
                permisos.eliminarEstablecimiento = true;
            }
        }
        else {
            if (company_type_code == "TRANS") {
                permisos.editarEstablecimiento = true;
                permisos.eliminarEstablecimiento = true;
                permisos.seleccionarCompania = true;
            }
            else if (company_type_code === "FWDR") {
                permisos.seleccionarCompania = true;
                permisos.editarEstablecimiento = false;
                permisos.eliminarEstablecimiento = false;
                // // Fwdr puede editar solo si no es Deposito, puerto o Transporte
                // if (
                //     ['DEP', 'PRT', 'TRANS'].indexOf(code_company_type) == -1
                // ) {
                //     permisos.editarEstablecimiento = true;
                // }
            }
        }

        return permisos
    }

    static infoTruck({ disk, brand, model, license, country_factory, color, factory_year, purchase_year, company, driver_name, observation_truck, active = true, is_owner, operador_transporte_nombre, truck_state_name, truck_type }) {
        return [
            {
                icon: '',
                title: 'Disco',
                desc: disk
            },
            {
                icon: '',
                title: 'Marca',
                desc: brand
            },
            {
                icon: '',
                title: 'Modelo',
                desc: model
            },
            {
                icon: '',
                title: 'Placa',
                desc: license
            },
            {
                icon: '',
                title: 'Color',
                desc: color
            },
            {
                icon: '',
                title: 'País de procedencia',
                desc: country_factory
            },
            {
                icon: '',
                title: 'Año de Fabricación',
                desc: factory_year
            },
            {
                icon: '',
                title: 'Año de Compra',
                desc: purchase_year
            },
            {
                icon: '',
                title: 'Propietario/Pto. emisión',
                desc: company
            },
            {
                icon: '',
                title: 'Operador de Transporte',
                desc: operador_transporte_nombre ? operador_transporte_nombre : ''
            },
            {
                icon: '',
                title: 'Conductor',
                desc: driver_name ? driver_name : ''
            },
            {
                icon: '',
                title: 'Estado de Registro',
                desc: (active) ? 'Activo' : 'No Activo'
            },
            {
                icon: '',
                title: 'Estado del Camión',
                desc: truck_state_name
            },
            {
                icon: '',
                title: 'Es Propietario',
                desc: (is_owner) ? 'Si' : 'No'
            },
            {
                icon: '',
                title: 'Tipo de Camión',
                desc: truck_type
            },
            {
                icon: 'comment',
                title: 'Observación',
                desc: observation_truck
            },
        ];
    }

    static infoTruckTech({ truck_serial, fuel, description, carroceria, cabin, passengers, g_tanque1, g_tanque2, tonnage, wheel5, avaluo }) {
        return [
            {
                icon: '',
                title: 'Serial',
                desc: truck_serial
            },
            {
                icon: '',
                title: 'Combustible',
                desc: fuel
            },
            {
                icon: '',
                title: 'Eje',
                desc: description
            },
            {
                icon: '',
                title: 'Carroceria',
                desc: carroceria
            },
            {
                icon: '',
                title: 'Cabina',
                desc: cabin
            },
            {
                icon: '',
                title: 'Pasajeros',
                desc: passengers
            },
            {
                icon: '',
                title: 'G Tanque 1',
                desc: g_tanque1
            },
            {
                icon: '',
                title: 'G Tanque 2',
                desc: g_tanque2
            },
            {
                icon: '',
                title: 'Tonelaje',
                desc: tonnage
            },
            {
                icon: '',
                title: '5ta Rueda (lbs)',
                desc: wheel5
            },
            {
                icon: '',
                title: 'Avalúo',
                desc: avaluo
            }
        ];
    }

    static error_messages_google_maps({ code }) {
        let errores = {
            "NOT_FOUND": 'Una coordenada ingresa no esta localizada.',
            "ZERO_RESULTS": "No se pudo generar la ruta entre la parada inicial y final.",
            "MAX_WAYPOINTS_EXCEEDED": "Número de paradas(waypoints) excede el limite permitido (25 paradas).",
            "MAX_ROUTE_LENGTH_EXCEEDED": "La ruta generada excede el maximo permitido, reducir instrucciones o paradas (waypoints).",
            "INVALID_REQUEST": "El requerimiento esta incompleto, Verificar información de envío.",
            "OVER_QUERY_LIMIT": "Muchas solicitudes generadas en un corto periodo de tiempo.",
            "REQUEST_DENIED": "El servicio Directions Service no está habilitado/permitido.",
            "UNKNOWN_ERROR": "Se generó un error en el servicio Directions Service."
        }

        return errores[`${code}`]
    }

    static infoMotor({ motor_brand, motor_model, motor_serial, caballaje, cilindraje }) {
        return [
            {
                icon: '',
                title: 'Marca',
                desc: motor_brand
            },
            {
                icon: '',
                title: 'Modelo',
                desc: motor_model
            },
            {
                icon: '',
                title: 'Serial',
                desc: motor_serial
            },
            {
                icon: '',
                title: 'Caballaje',
                desc: caballaje
            },
            {
                icon: '',
                title: 'Cilindraje',
                desc: cilindraje
            },
        ];
    }



    static infoBasic({ identification_card, nacionality, civil_status, escalafon_description = null }) {
        return [

            {
                icon: 'identification_card',
                title: 'Cédula',
                desc: identification_card
            },
            {
                icon: 'global',
                title: 'Nacionalidad',
                desc: nacionality
            },
            {
                icon: '',
                title: 'Estado civil',
                desc: civil_status
            }, {
                icon: '',
                title: 'Escalafón',
                desc: escalafon_description ? escalafon_description : ''
            }

        ];
    }

    static posicionAlfabeto = text => {
        var result = ''
        for (var i = 0; i < text.length; i++) {
            var code = text.toUpperCase().charCodeAt(i)
            if (code > 64 && code < 91) result += code - 64 + ' '
        }

        return result.slice(0, result.length - 1)
    }

    static validarCodigoCW1 = codigo => {
        const es_numero = (/^([0-9])*$/.test(codigo))

        return {
            estado: es_numero,
            mensaje: !es_numero ? 'Codigo CW1 - Ingresar solo números' : ''
        }
    }

    static validarCodigoBl = bl => {
        if (!bl || bl == undefined)
            return { estado: true }

        if (/\s/.test(bl))
            return { estado: false, mensaje: 'No ingresar espacios' }

        if (/^[a-zA-Z0-9]+$/.test(bl))
            return { estado: true, mensaje: 'Código Bl válido' }
        else
            return { estado: false, mensaje: 'Sólo ingresar números y letras' }

    }

    static validarCodigoContenedor = contenedor => {
        if (!contenedor || contenedor == undefined) {
            return { estado: true }
        }

        if (/\s/.test(contenedor)) {
            return { estado: false, mensaje: 'Error no debe contener espacios el número de contenedor' }
        }

        if (contenedor.length !== 11) {
            return { estado: false, mensaje: 'Contenedor debe tener 11 caracteres' }
        }

        if (contenedor.length === 11) {
            const digitoVerificador = parseInt(contenedor[10])

            let check = true
            let total = 0
            var array = contenedor.split('')
            for (let i = 0; i < 4; i++) {
                let letra = array[i]
                if (letra.toLowerCase() !== letra.toUpperCase()) {
                    // letra
                    total += this.codigo_alfabeto_contenedor[this.posicionAlfabeto(letra) - 1] * this.peso_alfabeto_contenedor[i]
                } else {
                    check = false
                }
            }
            var reg = /^\d+$/
            for (let i = 4; i < 10; i++) {
                let numero = array[i]
                if (reg.test(numero)) {
                    //  es num
                    total += parseInt(numero) * this.peso_alfabeto_contenedor[i]
                } else {
                    check = false
                }
            }

            var suma = total - parseInt(total / array.length) * array.length

            total = suma === 10 ? 0 : suma

            if (digitoVerificador === total)
                return { estado: true, mensaje: 'Es digito verificador' }

            return { estado: false, mensaje: 'El número de contenedor no es válido, no concuerda el digito verificador' }
        }
    }

    static infoEuipo({ disk, business_name, availableTxt, attributes = [], is_own, business_name_particular = null, size = null }) {
        const arrTemp = []
        arrTemp.push({ icon: '', title: 'DISCO', desc: disk })
        arrTemp.push({ icon: '', title: 'COMPAÑÍA', desc: business_name })

        for (const atr of attributes) {
            if (atr.code == "TRUCK" && atr.truck_company_owner)
                arrTemp.push({ icon: '', title: 'Transportista Prop.', desc: atr.truck_company_owner })

            if (atr.code == "TRUCK" && atr.truck_company_operator)
                arrTemp.push({ icon: '', title: 'Transportista Ope.', desc: atr.truck_company_operator })

            arrTemp.push({ icon: '', title: atr.name, desc: atr.value })
        }

        arrTemp.push({ icon: '', title: 'DISPONIBILIDAD', desc: availableTxt })
        arrTemp.push({ icon: '', title: 'ES PROPIO', desc: is_own ? 'SI' : 'NO' })

        if (size)
            arrTemp.push({ icon: '', title: 'TAMAÑO', desc: size })

        if (business_name_particular)
            arrTemp.push({ icon: '', title: 'COMPAÑIA PROPIETARIA MODEM', desc: business_name_particular })


        return arrTemp
    }

    static vencimientoLicencia({ date_expired }) {
        const fechaActual = moment()

        let fecha_vencimiento = moment(date_expired, this.dateFormat4)
        let duration = moment.duration(fecha_vencimiento.diff(fechaActual))

        if (duration.years() > 0) {
            return `${duration.years()} año(s)`
        }

        if (duration.years() == 0 && duration.months() > 0) {
            return `${duration.months()} mes(s)`
        }

        if (duration.years() == 0 && duration.months() == 0) {
            return `${duration.days()} dia(s)`
        }
    }

    static convert_millas_km(millas) {
        let km = 0
        km = (millas * (this.milla_km)).toFixed(2)
        return km
    }

    static convert_min_milisec(minutes) {
        let milisegundos = 0
        milisegundos = minutes * 60000
        return milisegundos
    }

    /**
     * Comprueba si dependiendo del intervalo de la alerta, se ejecuta la accion de avisar al usuario via visual y audio
     * @param {number} intervalo_alerta valor en milisegundos
     */
    static check_intervalo_alerta(intervalo_alerta) {
        const tiempo_actual = moment()
        const hora_actual = tiempo_actual.hour()
        const minuto_actual = tiempo_actual.minute()
        let notificar_alerta = false

        if (!intervalo_alerta)
            return false

        const intervalo_min = intervalo_alerta / 60000

        if (intervalo_min < 60) { //intervalos menos de una hora
            notificar_alerta = (minuto_actual % intervalo_min) == 0 ? true : false


        } else {//intervalos mayores a una hora
            if (minuto_actual == 0 && hora_actual >= 0) {
                const intervalo_hora = intervalo_min / 60
                notificar_alerta = (hora_actual % intervalo_hora) == 0 ? true : false
            }
        }


        return notificar_alerta
    }

    static infoContact({ email, address, phone, phone_work = null, asesor_comercial, team_operativo_name, team_operativo_name_users, instructions, internal_notes }) {

        const email_text = email && email.length > 0 ? email.replaceAll(',', ', ').replaceAll(';', ', ') : null;
        const infoContacto = [
            {
                icon: 'email',
                title: 'E-Mail',
                desc: email_text
            },
            {
                icon: 'home',
                title: 'Dirección',
                desc: address
            },
            {
                icon: 'phone',
                title: 'Teléfono Personal',
                desc: phone_work
            },
            {
                icon: 'phone',
                title: 'Teléfono Trabajo',
                desc: phone
            }
        ];

        if (asesor_comercial)
            infoContacto.push({
                icon: 'user',
                title: 'Asesor Comercial',
                desc: asesor_comercial
            })

        if (team_operativo_name) {
            const usuarios = team_operativo_name_users ? team_operativo_name_users.map(x => x.nombre_operativo).join(',') : '-'
            infoContacto.push({
                icon: 'user',
                title: 'Operativo',
                desc: <span>
                    {team_operativo_name}
                    <br />
                    {usuarios}
                </span>
            })
        }

        if (instructions)
            infoContacto.push({
                icon: 'file',
                title: 'Instrucciones',
                desc: instructions
            })

        if (internal_notes)
            infoContacto.push({
                icon: 'file',
                title: 'Notas Internas',
                desc: internal_notes
            })

        return infoContacto
    }

    static states_alerts() {
        let states = [
            {
                code: 'not-arrived',
                text: 'Llegada Tardía a Planta',
                color: 'red',
                status: 'processing'
            },
            {
                code: 'time_dep_prt',
                text: 'Standby Depósito',
                color: 'green',
                status: 'default'
            },
            {
                code: 'time_dep_prt',
                text: 'Standby Puerto',
                color: 'yellow',
                status: 'default'
            },
            {
                code: 'free_time',
                text: 'Standby Planta',
                color: 'blue',
                status: 'default'
            }
        ]


        return states
    }

    // static find_icon_by_alert(alertas, disk) {
    //     let estados = this.states_alerts()
    //     let iconos_alertas = []

    //     alertas.map(alerta => {
    //         let obj = estados.find(x => x.code == alerta.alert_code)
    //         if (obj)
    //             iconos_alertas.push(obj)
    //     })

    //     return iconos_alertas

    // }

    static infoLocation({ name, company, address, type, province, canton, name_contact, phone_contact, code_bic = null, free_hours = 0 }) {
        let array_info = [
            {
                icon: 'home',
                title: 'Nombre',
                desc: name
            },
            {
                icon: 'environment',
                title: 'Dirección',
                desc: address
            },
            {
                icon: 'company',
                title: 'Compañía',
                desc: company
            },
            {
                title: 'Tipo',
                desc: type
            },
            {
                title: 'Ciudad',
                desc: canton ? `${canton}/${province}` : null
            },
            {
                icon: 'user',
                title: 'Nombre de contacto',
                desc: name_contact ? name_contact : null
            },
            {
                icon: 'phone',
                type_icon: 'ant',
                title: 'Teléfono de contacto',
                desc: phone_contact ? phone_contact : null
            },
            {
                // icon: 'phone',
                // type_icon: 'ant',
                title: 'Horas Libres',
                desc: free_hours
            }
        ];

        if (code_bic)
            array_info.push({
                icon: 'number',
                title: 'Código BIC',
                type_icon: 'ant',
                desc: code_bic ? code_bic : '-'
            })

        return array_info
    }

    static vigencia_licencia_conductores = ({ licencia_expiracion }) => {
        var date_now = moment();
        let expired = moment(licencia_expiracion, this.dateFormat4);

        let respuesta = {
            className: '',
            dias_faltantes: null,
        }

        let dias = expired.diff(date_now, 'days')
        if (dias > 0 && dias <= 30)
            respuesta = {
                className: 'expirada',
                dias_faltantes: `Lic. expira en ${dias} días`
            }

        if (dias <= 0)
            respuesta = {
                className: 'expirada',
                dias_faltantes: `Licencia Expirada ${licencia_expiracion}`
            }

        return respuesta
    }


    static infoReservation({ company_client_name, appointment_date, appointment_time, reservation_type_name }) {
        return [
            {
                icon: 'user',
                title: 'Cliente',
                type_icon: 'ant',
                desc: company_client_name
            },
            {
                icon: 'clock-circle',
                title: 'Fecha',
                type_icon: 'ant',
                desc: `${appointment_date} ${appointment_time}`
            },
            {
                icon: 'global',
                title: 'Movimiento',
                type_icon: 'ant',
                desc: reservation_type_name
            }
        ];
    }

    static infoCompany({ name, ruc, travels, drivers, notifications_share, share_driver_truck, create_disv, nec_agent_foreign, early_billing }) {
        return [
            {
                icon: 'company',
                title: 'Nombre',
                desc: name
            },
            {
                icon: 'company',
                title: 'RUC',
                desc: ruc
            },
            {
                icon: 'map-drawing',
                title: 'Viajes realizados',
                desc: travels
            },
            {
                icon: 'user-o',
                title: 'Conductores activos',
                desc: drivers
            },
            {
                icon: 'notification',
                title: 'Notificaciones Compartidas (cliente)',
                desc: notifications_share ? 'SI' : 'NO'
            },
            {
                icon: 'link',
                title: 'Compartir Información de Viaje(Conductor y Camión)',
                desc: share_driver_truck ? 'SI' : 'NO'
            },
            {
                icon: 'file',
                title: 'Crear Disv',
                desc: create_disv ? 'SI' : 'NO'
            },
            {
                icon: 'user',
                title: 'Agente Extranjero',
                desc: nec_agent_foreign ? 'SI' : 'NO'
            },
            {
                icon: 'dollar',
                title: 'Facturación Anticipada',
                desc: early_billing ? 'SI' : 'NO'
            }
        ];
    }

    static infoDriverCompanies({ name_companies, travels }) {
        const companies = [];
        name_companies.map(name => {
            companies.push({
                icon: 'company',
                title: 'Empresa',
                desc: name
            })
        })

        return [
            ...companies,
            {
                icon: 'map-drawing',
                title: 'Viajes realizados',
                desc: travels
            }
        ];
    }

    static getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    /**
     * Set name and path to files to upload into s3
     * @param {File} file
     * @param {object} folderLevel | Define the structure of the path - level1, level2, level3
     */
    static setFileNameS3 = (file, folderLevel = { level1: null, level2: null, level3: null }) => {
        var fileName = file.name;
        var fileExt = fileName.split('.').pop();
        var fileNameTemp = (fileName.split('.')[0]);
        var pathS3 = `${folderLevel.level1 && folderLevel.level1.length > 0 ? `${folderLevel.level1}/` : ''}` +
            `${folderLevel.level2 && folderLevel.level2.length > 0 ? `${folderLevel.level2}/` : ''}` +
            `${folderLevel.level3 && folderLevel.level3.length > 0 ? `${folderLevel.level3}/` : ''}` +
            `${fileNameTemp}.${fileExt}`;
        return pathS3;
    }

    /**
     * Get Presigned URL from S3
     * @param { Array<FileObject> } fileList | lista de archivos
     * @param { string } type | Level 3 | Media resource type: 'LICENCIA', 'FOTO', 'CEDULA', 'CERTIFICADO','RESERVA'
     * @param { string } identification | id conductor / id company
     * @param { string } perfil | si el request lo envia DRIVER, COMPANY, TRUCK, GENERATOR, CHASSIS
     * @param {number} id_reserva id de reserva aplica para orden de retiro
     * @param {string} tipo_documento_reserva tipo de documento de una reserva: RETIRO
     * @return {Array} fileListPresigned
     * @return {null} null
     * ----------------------------------------------------------------------------
     * @typedef FileObject
     * @property {File} file
     * @property {string} type | Level 3 | Media resource type: 'LICENCIA', 'FOTO', 'CEDULA', 'CERTIFICADO', 'RESERVA'
     */
    static getPresignedUrl = async (fileList = [], type, identification, perfil, id_reserva = null, tipo_documento_reserva = null) => {
        // console.log(fileList)
        // console.log(type)
        // console.log(identification)
        // console.log(perfil)

        const fileListPresigned = [];
        const statusProces = true;
        const size = fileList.length;

        for (var index = 0; index < size; index++) {
            const file = fileList[index];
            // console.log(file)

            if (statusProces) {
                const presignedUrl = await RestClientObj.preSignedUrlFiles({
                    filename: (file.name == undefined) ? file.file.name : file.name,
                    type: type ? type : file.type,
                    identification: identification,
                    perfil: perfil,
                    id_reserva,
                    tipo_documento_reserva
                }).then(urlObj => {
                    // console.log(urlObj)
                    return urlObj.url;

                }).catch(error => {
                    statusProces = false;
                    // console.log(error)
                    return null
                });
                if (presignedUrl)
                    fileListPresigned.push({ url: presignedUrl, file });
            }
        }

        if (statusProces)
            return fileListPresigned
        else
            return null; // Ocurrió algún problema con algún archivo
    }

    /**
     * Get a file to upload into an URL
     * @param {*} url
     * @param {*} file
     * @param {*} callbackOnSuccess
     * @param {*} calbackOnProgress
     * @param {*} callbackOnError
     */
    static uploadFileToS3(url, file,
        callbackOnSuccess = (res) => { return res },
        calbackOnProgress = (percent) => { return percent },
        callbackOnError = (message = "") => { return message }
    ) {
        var xhr = new XMLHttpRequest();
        var secuencia = 0;

        // console.log("FINAL FILE", file, url)

        xhr.open("PUT", url);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200 || xhr.status === 204 || xhr.status === 503) {
                    let objFile = { resource_name: file.name };
                    callbackOnSuccess(objFile)
                } else {
                    callbackOnError("Error subiendo el archivo")
                }
            }
        }.bind(this);

        xhr.upload.onprogress = function (evt) {
            var percentComplete = (evt.loaded / evt.total) * 100;
            calbackOnProgress(percentComplete);
        }.bind(this);

        xhr.send(file);
    }


    static get_image_base64(url_image) {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();
            request.open('GET', url_image, true);
            request.responseType = 'blob';
            request.onload = function () {
                var reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload = function (e) {
                    resolve(e.target.result)
                };
            };
            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    if (request.status !== 200) {
                        reject(null)
                    }
                }
            }.bind(this);
            request.send();
        })

    }

    static base64_to_file(codeBase64, filename) {
        var arr = codeBase64.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }


    static dibujarRutaMapa(paradas = []) {
        let result = []
        let waypoints = []

        // console.log('paradas', paradas)
        if (paradas == null) {
            return new Promise((resolve, reject) => {
                resolve(result)
            })
        }

        if (paradas.length == 0)
            return new Promise((resolve, reject) => {
                resolve(result)
            })

        const DirectionsService = new window.google.maps.DirectionsService()
        const origen = paradas[0]
        const destino = paradas[paradas.length - 1]

        paradas.map((parada, index) => {
            if (index > 0 && index < paradas.length - 1)
                if (parada.location_lat && parada.location_lng)
                    waypoints.push({
                        location: new window.google.maps.LatLng(Number(parada.location_lat), Number(parada.location_lng)),
                        stopover: true
                    })
        })

        return new Promise((resolve, reject) => {
            DirectionsService.route({
                origin: new window.google.maps.LatLng(Number(origen.location_lat), Number(origen.location_lng)),
                destination: new window.google.maps.LatLng(Number(destino.location_lat), Number(destino.location_lng)),
                waypoints: waypoints,
                travelMode: window.google.maps.TravelMode.DRIVING
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    resolve(result)
                } else {
                    reject([])
                }
            })
        })
    }

    static rentabilidad(tarifa = undefined, costo = undefined, distancia = undefined) {
        if (tarifa == undefined || costo == undefined || distancia == undefined)
            return 'Rentabilidad no definida'
        if (tarifa.tarifa == null || costo == null)
            return 'Rentabilidad no definida'
        return `${(((parseFloat(tarifa.tarifa.fee_cost) - parseFloat(costo.ratio * distancia)) / parseFloat(costo.ratio * distancia)) * 100).toFixed(2)}% rentabilidad`
    }

    static isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    static distanceToKm(distancia) {
        if (distancia == 0)
            return 0
        return `${(distancia / 1000).toFixed(2)}km`
    }

    static durationToHoursMinute(duration) {
        return `${Math.floor(duration / 3600)}h ${Math.floor((duration / 60) % 60)}m`
    }

    static calculoPorcentageProgresoViaje({ eta_avance = 0, total_viaje = 0 }) {
        if (total_viaje == 0)
            return 0

        const resultado = 100 - Math.round(Math.abs((eta_avance * 100) / total_viaje))
        return resultado < 0 ? 0 : resultado
    }

    /**
     * Validación para Cédula
     * @param {*} rule
     * @param {*} value
     * @param {*} callback
     */
    static validateDNI = (rule, value, callback) => {
        if ((!/^[0-9]*$/i.test(value)) || (value.length != 10)) {
            callback("La cédula es inválida");
        } else {
            callback();
        }
    };

    static flattenDeep = (arr1) => {
        return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val), []);
    }

    static decode_overview_polyline = ({ overview_polyline, precision = 5 }) => {
        var index = 0,
            lat = 0,
            lng = 0,
            coordinates = [],
            shift = 0,
            result = 0,
            byte = null,
            latitude_change,
            longitude_change,
            factor = Math.pow(10, Number.isInteger(precision) ? precision : 5);

        // Coordinates have variable length when encoded, so just keep
        // track of whether we've hit the end of the string. In each
        // loop iteration, a single coordinate is decoded.
        while (index < overview_polyline.length) {

            // Reset shift, result, and byte
            byte = null;
            shift = 0;
            result = 0;

            do {
                byte = overview_polyline.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

            shift = result = 0;

            do {
                byte = overview_polyline.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

            lat += latitude_change;
            lng += longitude_change;

            coordinates.push({ lat: lat / factor, lng: lng / factor });
        }

        return coordinates;
    }

    static sorterString = (a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        // a debe ser igual b
        return 0;
    }

    static orderTablaMonitoreo = (a, b, sorter) => {
        if (sorter.order == 'ascend') {
            if (a[`${sorter.key}`] < b[`${sorter.key}`])
                return -1;

            if (a[`${sorter.key}`] > b[`${sorter.key}`])
                return 1;

        } else {
            if (b[`${sorter.key}`] < a[`${sorter.key}`])
                return -1;

            if (b[`${sorter.key}`] > a[`${sorter.key}`])
                return 1;
        }
        // a debe ser igual b
        return 0;
    }

    /**
     * Ordener por variables moments
     * @param {moment} a
     * @param {moment} b
     * @returns
     */
    static sorterDates = (a, b) => {
        if (a.valueOf() < b.valueOf()) {
            return -1;
        }
        if (a.valueOf() > b.valueOf()) {
            return 1;
        }
        // a debe ser igual b
        return 0;
    }

    static calculoCostosTPTPropios = ({ parametros_costos_tpt_propios, rubros_costos_reserva }) => {
        const { costo_hour, costo_km, costo_peaje_ruta, hora_recorrido, km_recorrido, } = parametros_costos_tpt_propios || { costo_hour: 0, costo_km: 0, costo_peaje_ruta: 0, hora_recorrido: 0, km_recorrido: 0 }

        const NOMBRES_RUBROS_COSTOS_REPORTE_COMERCIAL = this.NOMBRES_RUBROS_COSTOS_REPORTE_COMERCIAL


        let total_rubros_costos = 0
        if (rubros_costos_reserva && rubros_costos_reserva.length != 0) {
            for (const rubro of rubros_costos_reserva) {
                if (NOMBRES_RUBROS_COSTOS_REPORTE_COMERCIAL.includes(rubro.description)) {
                    total_rubros_costos += (parseFloat(rubro.subtotal) + parseFloat(rubro.iva))
                }
            }
        }

        const costo_propio_tpt = (parseFloat(costo_km) * parseInt(km_recorrido)) + (parseFloat(costo_hour) * parseInt(hora_recorrido)) + parseInt(costo_peaje_ruta) + total_rubros_costos

        return parseFloat(costo_propio_tpt.toFixed(2))

    }

    static calculoRubrosCostosCategoria = ({ rubros_costos_reserva, rubroNombre }) => {
        let total_rubros_costos = 0
        const NOMBRES_RUBROS_COSTOS_REPORTE_COMERCIAL = this.NOMBRES_RUBROS_COSTOS_REPORTE_COMERCIAL

        if (rubros_costos_reserva && rubros_costos_reserva.length != 0) {
            for (const rubro of rubros_costos_reserva) {
                if (rubroNombre == 'OTROS') {
                    if (!NOMBRES_RUBROS_COSTOS_REPORTE_COMERCIAL.includes(rubro.description)) {
                        total_rubros_costos += (parseFloat(rubro.subtotal) + parseFloat(rubro.iva))
                    }
                } else {
                    if (rubro.description == rubroNombre) {
                        total_rubros_costos += (parseFloat(rubro.subtotal) + parseFloat(rubro.iva))
                    }
                }
            }
        }

        return total_rubros_costos
    }
}

export default UtilsObj;
